<template>

        <v-list dense  v-if="value && value.length > 0" class="pa-0">
          <draggable :value="value"
                     handle=".drag-handle"
                     @start="isDragging = true"
                     @end="isDragging = false"
                     @change="onMove"
                     ghost-class="ghost">
            <transition-group type="transition" name="flip-list">
              <v-list-item class="bb order-action" v-for="(orderItem, index) of value" :key="orderItem.field">
                <v-list-item-avatar>
                  <v-tooltip :open-delay="300" top :disabled="isDragging">
                    <template v-slot:activator="{on}">

                      <v-btn v-on="on" text fab x-small class="drag-handle">
                        <v-icon>mdi-reorder-horizontal</v-icon>
                      </v-btn>
                    </template>
                    <span>
                  Segure e arraste
                </span>
                  </v-tooltip>
                </v-list-item-avatar>
                <v-list-item-subtitle v-text="orderItem.name"/>
                <v-list-item-action>
                  <v-btn @click="moveUp(index)" color="primary" :disabled="index === 0" x-small fab class="elevation-0">
                    <v-icon>mdi-arrow-up</v-icon>
                  </v-btn>
                </v-list-item-action>
                <v-list-item-action>
                  <v-btn @click="moveDown(index)" color="primary" :disabled="index === value.length -1" x-small fab class="elevation-0">
                    <v-icon>mdi-arrow-down</v-icon>
                  </v-btn>

                </v-list-item-action>
              </v-list-item>
            </transition-group>

          </draggable>
        </v-list>
</template>

<script>

import draggable from 'vuedraggable'
import {reorderArray} from "../../../utils";
export default {
  name: "OrderInput",
  components: {
    draggable
  },
  props: {
    value: {
      type: Array
    }
  },
  data () {
    return {
      isDragging: false,
    }
  },
  methods: {
    onMove({moved}) {
      const result = reorderArray(this.value, moved.oldIndex, moved.newIndex);
      this.$emit('input', result);
    },
    moveUp(index) {
      const result = reorderArray(this.value, index, index - 1);
      this.$emit('input', result);
      },
    moveDown(index) {
      const result = reorderArray(this.value, index, index + 1);
      this.$emit('input', result);
    }
  }
}
</script>

<style scoped lang="scss">
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}

</style>